<template>
  <div style="width: 100%;padding: 10px;box-sizing: border-box">
    <el-table :data="dataList">
      <el-table-column type="index" prop="index" label="序号" width="100px"></el-table-column>
      <el-table-column prop="" label="学生" width="140px">
        <template slot-scope="scope">
          {{ scope.row.student.student_lastName + scope.row.student.student_givenName }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="Title"></el-table-column>
      <el-table-column prop="" label="Category">
        <template slot-scope="scope">
          {{ scope.row.type.name }}
        </template>
      </el-table-column>
      <el-table-column prop="entry_type" label="Entry Type"></el-table-column>
      <el-table-column prop="" label="Entry截止日期">
        <template slot-scope="scope">
          {{ scope.row.custom_ddl_time ? formatDllTime(scope.row.custom_ddl_time) : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="100px">
        <template slot-scope="scope">
          <el-button @click="viewEntry(scope.row)" type="primary" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {getReviewEntries} from '../../api/eae'
import {formatDate} from "../../utils/date";

export default {
  name: "EntryList",
  data() {
    return {
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    viewEntry(row) {
      this.$router.push({
        path: "entryEdit",
        query: {
          id: row._id.$id,
        },
      });
    },

    formatDllTime(custom_ddl_time) {
      if (custom_ddl_time && custom_ddl_time != '') {
        return formatDate(new Date(custom_ddl_time * 1000), 'yyyy-MM-dd')
      }
      return ''
    },

    fetchData() {
      getReviewEntries().then((res) => {
        this.dataList = res.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>
